<template>
  <div class="home">
    <sui-container>
      <sui-grid>
        <sui-grid-row id="header">
          <sui-grid-column :width="16">
            <img src="@/assets/tsaro-logo-header.png" id="logo">
            <span id="logo-title">Voice Control</span>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
      <sui-grid>
        <sui-grid-row>
          <sui-grid-column :width="8" :mobile="16" :tablet="8" :computer="8">
            <h2 is="sui-header" dividing>Inside Elevator</h2>
            <div class="vertical-line"></div>
            <sui-list class="list-text">
              <sui-list-item>
                <span class="list-number">1</span>
                <span class="list-first">
                                    Say wake word &ldquo;<b>Elevator</b>&rdquo;
                                </span>
                <span class="list-second">
                                    <ol>
                                        <li>Wake word detected - <span class="green">WHITE</span> Light Turns On </li>
                                        <li>Wake word not detected - No Light, Go to <span class="list-number">1</span></li>
                                        </ol>
                                </span>
              </sui-list-item>
              <sui-list-item>
                <span class="list-number">2</span>
                <span class="list-first">Say voice command &ldquo;<b>Floor #</b>&rdquo;,&nbsp;&nbsp; &ldquo;<b>Ground Floor</b>&rdquo; or &ldquo;<b>Basement #</b>&rdquo; </span>

                <span class="list-second">
                                    <ol>
                                        <li>Voice command detected - corresponding floor button activates </li>
                                    <li>Voice command not detected - <span class="orange">RED</span> Light Turns ON, Go to
                                    <span class="list-number">1</span></li>
                                        </ol>
                                </span>
              </sui-list-item>
              <sui-list-item>
                <span class="list-number">#</span>
                <span class="list-first">Other command is also available: </span>

                <span class="list-second">
      <ul>
        <li>Open Door </li>
        <li>Close Door </li>
        <li>Call Help </li>
      </ul>
                                </span>
              </sui-list-item>
            </sui-list>


            <sui-divider clearing hidden></sui-divider>
          </sui-grid-column>
          <sui-grid-column :width="8" :mobile="16" :tablet="8" :computer="8">
            <h2 is="sui-header" dividing>Hall Station</h2>
            <div class="vertical-line"></div>
            <sui-list class="list-text">
              <sui-list-item>
                <span class="list-number">1</span>
                <span class="list-first">
                                    Say wake word &ldquo;<b>Elevator</b>&rdquo;
                                </span>
                <span class="list-second">
                                    <ol>
                                        <li>Wake word detected - <span class="green">WHITE</span> Light Turns On </li>
                                        <li>Wake word not detected - No Light, Go to <span class="list-number">1</span></li>
                                    </ol>

                                </span>
              </sui-list-item>
              <sui-list-item>
                <span class="list-number">2</span>
                <span class="list-first">Say voice command &ldquo;<b>Going Up</b>&rdquo; or &ldquo;<b>Going Down</b>&rdquo;</span>
                <span class="list-second">
                                    <ol>
                                        <li>Voice command detected - Up or Down button activates</li>
                                        <li>Voice command not detected - <span class="orange">RED</span> Light Turns ON, Go to <span
                                            class="list-number">1</span></li>
                                    </ol>
                                </span>
              </sui-list-item>
            </sui-list>
            <sui-divider clearing hidden></sui-divider>
          </sui-grid-column>
        </sui-grid-row>
        <sui-grid-row>
          <sui-grid-column :width="12">
            <p id="footer">Learn more about Tsaro Voice Control @ <a href="//www.tsarosolutions.com/tsaro-voice"
                                                                     target="_blank">www.tsarosolutions.com/tsaro-voice</a>
            </p>
          </sui-grid-column>
        </sui-grid-row>
        <sui-grid-row>
          <sui-table compact unstackable>
            <sui-table-header>
              <sui-table-header-cell></sui-table-header-cell>
              <sui-table-header-cell>English</sui-table-header-cell>
              <sui-table-header-cell>Français</sui-table-header-cell>
            </sui-table-header>
            <sui-table-body>
              <sui-table-row>
                <sui-table-cell>Wake Word</sui-table-cell>
                <sui-table-cell>Elevator</sui-table-cell>
                <sui-table-cell>Ascenseur</sui-table-cell>
              </sui-table-row>


              <sui-table-row>
                <sui-table-cell rowspan="2">Hall Station</sui-table-cell>
                <sui-table-cell>Going Up</sui-table-cell>
                <sui-table-cell>Monter</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Going Down</sui-table-cell>
                <sui-table-cell>Descente</sui-table-cell>
              </sui-table-row>


              <sui-table-row>
                <sui-table-cell rowspan="19">Inside Elevator Cab</sui-table-cell>
                <sui-table-cell>
                  floor One,<br>
                  First Floor
                </sui-table-cell>
                <sui-table-cell>Étage Un</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Two,<br>
                  Second Floor
                </sui-table-cell>
                <sui-table-cell>Étage Deux</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Three ,<br>
                  Third Floor
                </sui-table-cell>
                <sui-table-cell>Étage Trois</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Four,<br>
                  Fourth Floor
                </sui-table-cell>
                <sui-table-cell>Étage Quatre</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Five,<br>
                  Fifth Floor
                </sui-table-cell>
                <sui-table-cell>Étage Cinq</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Six,<br>
                  Sixth Floor
                </sui-table-cell>
                <sui-table-cell>Étage Six</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Seven,<br>
                  Seventh Floor
                </sui-table-cell>
                <sui-table-cell>Étage Sept</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Eight,<br>
                  Eighth Floor
                </sui-table-cell>
                <sui-table-cell>Étage Huit</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Nine,<br>
                  Ninth Floor
                </sui-table-cell>
                <sui-table-cell>Étage Neuf</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Ten,<br>
                  Tenth Floor
                </sui-table-cell>
                <sui-table-cell>Étage Dix</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  Floor Eleven ,<br>
                  Eleventh Floor
                </sui-table-cell>
                <sui-table-cell>Étage Onze</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Ground Floor</sui-table-cell>
                <sui-table-cell>Rez-De-Chaussée</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Basement One</sui-table-cell>
                <sui-table-cell>Sous-Sol Un</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Basement Two</sui-table-cell>
                <sui-table-cell>Sous-Sol Deux</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Basement Three</sui-table-cell>
                <sui-table-cell>Sous-Sol Trois</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Basement Four</sui-table-cell>
                <sui-table-cell>Sous-Sol Quatre</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Open Door<br>Open the Door<br>Hold Door</sui-table-cell>
                <sui-table-cell>Ouvre La Porte</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Close Door<br>Close the Door</sui-table-cell>
                <sui-table-cell>Ferme La Porte</sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>Call for Help<br>
                  Call Help
                </sui-table-cell>
                <sui-table-cell>Appeler à l'aide<br>
                  Appeler l'aide"
                </sui-table-cell>
              </sui-table-row>
            </sui-table-body>
          </sui-table>
        </sui-grid-row>
      </sui-grid>

    </sui-container>


  </div>
</template>

<style>
#header {
  padding-top: 2.5em;
}

#logo {
  max-width: 150px;
  height: 2em;
  float: left;
}

#logo-title {
  float: left;
  margin-left: 0.3em;
  line-height: 1em;
  font-size: 2em;
  font-weight: bolder;
}

.list-text .item {
  font-size: 1.4em;
}

.list-number {
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 1.3em;
  border: 2px dashed #9b9898;
  margin-right: 0.5em;
  background-color: white;

}

.list-first {
  display: block;
  margin-left: 2em;
  margin-bottom: 0.8em;
  line-height: 1.5em;
}

.list-second {
  clear: both;
  display: block;
  margin-left: 2em;
  margin-bottom: 0.8em;
  line-height: 1.5em;
}

.vertical-line {
  border-left: 2px solid grey;
  height: 100%;
  left: 2em;
  top: 2.2em;
  position: absolute;
  z-index: -2;
}

.green {
  fill-opacity: 1;
  color: white;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
  font-weight: bolder;

}

.orange {
  color: #f10000;
}

h2 {
  background-color: white;
  z-index: 100;
}


ol {
  list-style: lower-alpha;
}

#footer {
  margin-top: 50px;
  font-size: 0.9em;
}
</style>

<script>

export default {
  name: 'Home',
  components: {}
}
</script>
